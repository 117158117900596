import React from "react";
import get from "lodash/get";

import { graphql } from "gatsby";

import Layout from "../components/global/layout";
import Container from "../components/global/container";
import InnerPageHeader from "../components/inner-page-header/inner-page-header";

class Privacy extends React.Component {
    render() {
        const meta = get(this, "props.data.meta");
        const { location } = this.props;

        return (
            <Layout
                title={`Privacy Policy | ${meta.siteMetadata.title}`}
                location={location}
            >
                <InnerPageHeader pageTitle="Privacy Policy" />
                <article>
                    <section className="py-60 background-black text-white content">
                        <Container>
                            <div className="row">
                                <div className="col-12 col-md-10 offset-md-1">
                                    <p>
                                        Last updated:{" "}
                                        <strong>May 20, 2021</strong>
                                    </p>
                                    <p>
                                        Thank you for using The Unifyd app (“Unifyd”
                                        or “the App”), a DeFi application
                                        operated by Unifyd. (“Unifyd”, “we”, or “us”).
                                        When users (“users” or “you”) engage
                                        with the App, we will collect
                                        Information from your device, which may
                                        include personal information or personal
                                        data, as defined by applicable privacy
                                        laws (“the “Information”). This
                                        Information will be handled as described
                                        in this Privacy Policy. By accessing the
                                        App, you consent to the collection, use,
                                        and other handling of your Information
                                        as described below.{" "}
                                        <strong>
                                            If you do not agree to this privacy
                                            policy, please do not use the App.
                                        </strong>
                                    </p>
                                    <h2 id="information-we-collect-from-users">
                                        1. Information We Collect From Users
                                    </h2>
                                    <p>
                                        <strong>
                                            Information You Submit to Us
                                        </strong>
                                    </p>
                                    <p>
                                        This includes any Information that you
                                        may provide to us through the App, such
                                        as:
                                    </p>
                                    <ul>
                                        <li>
                                            <p>Your search queries,</p>
                                        </li>
                                        <li>
                                            <p>
                                                Your interactions with the App’s
                                                content,
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Your communications with us via
                                                email.
                                            </p>
                                        </li>
                                    </ul>
                                    <p>
                                        <strong>
                                            Automatically Collected Information
                                        </strong>
                                    </p>
                                    <p>
                                        We and third parties may use automated
                                        means to collect Information about you,
                                        your device, and your use of the App. We
                                        may do so through technologies that
                                        collect or transmit Information from the
                                        App (sometimes called “APIs”) or through
                                        technology embedded in the App
                                        (sometimes called “SDKs”), which may be
                                        operated by us.
                                    </p>
                                    <p>
                                        <strong>
                                            This Information includes:
                                        </strong>
                                    </p>
                                    <ul>
                                        <li>
                                            <p>
                                                IP address (from which general
                                                location may be inferred)
                                            </p>
                                        </li>
                                        <li>
                                            <p>User Agent</p>
                                        </li>
                                        <li>
                                            <p>Network connection type</p>
                                        </li>
                                        <li>
                                            <p>
                                                Android Ad IDs and, for devices
                                                running iOS13 and prior, IDFAs
                                                (collectively, “Device IDs”)
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                General device information (e.g.
                                                device type, operating system,
                                                and screen size)
                                            </p>
                                        </li>
                                        <li>
                                            <p>Language used by your device</p>
                                        </li>
                                        <li>
                                            <p>
                                                Information about the App (e.g.
                                                App ID, App name, and App
                                                version)
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Information about your
                                                interactions with the App (e.g.
                                                which pages you visit).
                                            </p>
                                        </li>
                                    </ul>
                                    <p>
                                        Additionally, when the App contains
                                        links to web content, third parties may
                                        collect data through cookies or pixels,
                                        which may collect unique identifiers and
                                        other log data.
                                    </p>
                                    <p>
                                        When you use the App, we and our service
                                        providers may ask your permission to
                                        collect your general location
                                        Information (e.g. city and state)
                                        through an SDK in the App. The App may
                                        use this location Information to provide
                                        region-based services, content, and
                                        other Information that may be of
                                        interest to you. If you do not wish to
                                        have general location Information
                                        collected and used by us and our service
                                        providers, you may disable the location
                                        features on your device.
                                    </p>
                                    <h2 id="use-of-your-information">
                                        2. Use of Your Information
                                    </h2>
                                    <p>
                                        We may use the information we collect
                                        for various purposes, including, but not
                                        limited to:
                                    </p>
                                    <ul>
                                        <li>
                                            <p>To provide you the App;</p>
                                        </li>
                                        <li>
                                            <p>
                                                To personalize your experiences
                                                while using our App;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                To detect, investigate or
                                                respond to security issues;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                To analyze, customize and
                                                personalize the App;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                To improve the App and develop
                                                innovative features and new
                                                services;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                To safeguard our and others’
                                                rights or property;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                To investigate, prevent, or take
                                                action regarding unlawful or
                                                harmful activities, including
                                                potential threats to the
                                                physical safety of a person,
                                                potential fraud, and violations
                                                of our Terms of Service;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                To otherwise administer and
                                                troubleshoot the App;
                                            </p>
                                        </li>
                                    </ul>
                                    <h2 id="sharing-your-information">
                                        3. Sharing Your Information
                                    </h2>
                                    <p>
                                        We may share your Information in the
                                        following ways, or for the following
                                        reasons:
                                    </p>
                                    <ul>
                                        <li>
                                            <p>With our affiliates.</p>
                                        </li>
                                        <li>
                                            <p>
                                                With third parties that help us
                                                operate our business and provide
                                                our services, such as
                                                contractors that provide us with
                                                technology, services, data, or
                                                content.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                To tailor editorial content and
                                                advertising to you, when you use
                                                the App or interact with other
                                                services, apps, or content;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                When we believe that doing so is
                                                necessary to protect, enforce,
                                                or defend the legal rights,
                                                privacy, safety, or property of
                                                Unifyd., our employees or agents or
                                                users, or to comply with
                                                applicable law or legal process,
                                                including responding to requests
                                                from public and government
                                                authorities.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                To transfer the Information we
                                                hold in the event we sell or
                                                transfer all or a portion of our
                                                business or assets (such as in
                                                connection with a merger,
                                                acquisition, reorganization,
                                                dissolution or liquidation) or
                                                take steps in anticipation of
                                                such a transaction.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                We may share aggregated or
                                                de-identified data without
                                                restriction.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                In addition to the sharing
                                                described in this Privacy
                                                Policy, we may share information
                                                about you with third parties
                                                whenever you consent to or
                                                direct such sharing.
                                            </p>
                                        </li>
                                    </ul>
                                    <h2 id="third-parties-and-online-analytics-providers">
                                        4. Third Parties and Online Analytics
                                        Providers
                                    </h2>
                                    <p>
                                        To help us better understand your use of
                                        the App, we may use third-party
                                        analytics on our App. These third
                                        parties use the sorts of technologies
                                        described in the “Automatically
                                        Collected Information” section above.
                                        The information collected by this
                                        technology will be disclosed to or
                                        collected directly by these service
                                        providers.
                                    </p>
                                    <p>
                                        The App may also contain link to
                                        third-party websites, applications, and
                                        services. If you choose to use those
                                        sites or services, you may disclose your
                                        Information to those third parties.
                                        Because these third-party websites and
                                        services are not operated by Unifyd, we are
                                        not responsible for the content or
                                        practices of those websites or services.
                                        The collection, use, and disclosure of
                                        your Information will be subject to the
                                        privacy policies of the third-party
                                        websites or services, and not this
                                        Privacy Policy. We urge you to read
                                        their privacy and security policies for
                                        additional information.
                                    </p>
                                    <h2 id="security">5. Security</h2>
                                    <p>
                                        We take technical and organizational
                                        measures to protect your data against
                                        accidental or unlawful destruction or
                                        accidental loss, alteration,
                                        unauthorized disclosure or access.
                                        However, no method of transmission over
                                        the Internet, or method of electronic
                                        storage, is absolutely secure.
                                        Therefore, while we strive to protect
                                        your information, we cannot guarantee
                                        its absolute security.
                                    </p>
                                    <h2 id="children">6. Children</h2>
                                    <p>
                                        The App is not intended for use by
                                        children and we do not knowingly collect
                                        personal information from children under
                                        the age of sixteen (16). If you are the
                                        parent or guardian of a child under the
                                        age of sixteen (16) and believe we have
                                        unknowingly collected Information about
                                        your child, please contact us at{" "}
                                        <a href="mailto:privacy@unifyd.finance">
                                            privacy@unifyd.finance
                                        </a>{" "}
                                        and we will investigate.
                                    </p>
                                    <h2 id="notice-to-individuals-in-the-state-of-california">
                                        7. Notice to Individuals in the State of
                                        California
                                    </h2>
                                    <p>
                                        California residents, under the
                                        California Consumer Privacy Act of 2018,
                                        Cal. Civil Code § 1798.100 et seq. (the
                                        “CCPA”), have the right to request that
                                        we (i) provide you with access to the
                                        Personal Information that we hold about
                                        you, (ii) correct your Personal
                                        Information, (iii) delete your Personal
                                        Information, and (iv) cease or restrict
                                        disclosures or sales of your Personal
                                        Information to third parties. You also
                                        have a right against discrimination for
                                        exercising any of these rights, which we
                                        are committed to upholding and honoring
                                        at all times.
                                    </p>
                                    <p>
                                        If you would like to opt out of our
                                        disclosures and sales of your Personal
                                        Information, please contact us at&nbsp;
                                        <a href="mailto:privacy@unifyd.finance">
                                            privacy@unifyd.finance
                                        </a>
                                        .
                                    </p>
                                    <p>
                                        <strong>
                                            Categories of Data that We Hold
                                            about You
                                        </strong>
                                    </p>
                                    <p>
                                        We do not know your identity because we
                                        do not process your name, email address,
                                        or other identifiable information.
                                        Instead, we only process digital
                                        identifiers such as cookie IDs, IP
                                        addresses, mobile advertising IDs on
                                        your device, browsing history and
                                        associated preferences.
                                    </p>
                                    <p>
                                        <strong>
                                            Why and Where your Personal
                                            Information is Collected
                                        </strong>
                                    </p>
                                    <p>
                                        We process your Personal Information in
                                        order to provide personalized content to
                                        you. We automatically collect user
                                        Information when users interact with the
                                        App.
                                    </p>
                                    <p>
                                        <strong>
                                            How Your Personal Information is
                                            Shared
                                        </strong>
                                    </p>
                                    <p>
                                        We may disclose or make available your
                                        pseudonymous Personal Information to our
                                        trusted partners. When we do so, we have
                                        contractually restricted their uses of
                                        this data for only Taboola’s business
                                        purposes. Under the CCPA, such
                                        disclosures of Personal Information to
                                        service providers are not deemed to be a
                                        “sale.”
                                    </p>
                                    <h2 id="notice-to-international-users">
                                        8. Notice to International Users
                                    </h2>
                                    <p>
                                        Unifyd. operates in the United States and
                                        as a result, the Information we collect
                                        will be used only for the purposes set
                                        forth in this Privacy Policy and will be
                                        stored in the United States. By using
                                        the App, you acknowledge and consent
                                        that we may transfer your Information
                                        outside the country in which it is
                                        collected, including to a country that
                                        may not offer the same level of
                                        protection for Information as the
                                        country in which you reside. Rest
                                        assured however, that our parent company
                                        has arranged all contractual safeguards
                                        to ensure your Information is processed
                                        in a way that offers an adequate level
                                        of protection when it is transferred or
                                        shared between or among Unifyd’s
                                        Affiliates, subsidiaries, and/or parent
                                        companies.
                                    </p>
                                    <h2 id="changes-to-this-privacy-policy">
                                        9. Changes to this Privacy Policy
                                    </h2>
                                    <p>
                                        This Privacy Policy is current as of the
                                        Effective Date set forth above.
                                    </p>
                                    <p>
                                        We may change this Privacy Policy from
                                        time to time, so please be sure to check
                                        back periodically. We will post any
                                        changes to this Privacy Policy within
                                        the Unifyd App.
                                    </p>
                                    <p>
                                        If we make any changes to this Privacy
                                        Policy that materially affect our
                                        practices with regard to the Information
                                        we have previously collected from you,
                                        we will endeavor to provide you with
                                        notice in advance of such change by
                                        highlighting the change on our Sites. We
                                        will seek your prior consent to any
                                        material changes, if and where this is
                                        required by applicable data protection
                                        laws.
                                    </p>
                                    <p>
                                        Please review the changes carefully. If
                                        you disagree with the Privacy Policy,
                                        you must discontinue use of Unifyd.
                                    </p>
                                    <h2 id="how-to-contact-us">
                                        10. How to Contact Us
                                    </h2>
                                    <p>
                                        If you have any requests, questions,
                                        comments, or concerns regarding our
                                        Privacy Policy or practices, please
                                        contact us as at{" "}
                                        <a href="mailto:privacy@unifyd.finance">
                                            privacy@unifyd.finance
                                        </a>
                                        .
                                    </p>
                                </div>
                            </div>
                        </Container>
                    </section>
                </article>
            </Layout>
        );
    }
}

export default Privacy;

export const PrivacyQuery = graphql`
    query Privacy {
        meta: site {
            siteMetadata {
                brandName
                description
                fullBrandName
                title
                siteUrl
            }
        }
    }
`;

import React from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import Seo from "./seo";

const Layout = ({ children, title, description, location }) => {
    return (
        <>
            <Header location={location} title={title} />
            <Seo title={title} description={description} />
            <main>{children}</main>
            <Footer />
        </>
    );
};

export default Layout;

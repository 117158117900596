import React, { useState } from "react";
import Container from "../global/container";
import * as styles from "./header.module.scss";
import Logo from "../../../static/logo.svg";
import FlexSpace from "../global/flex-space";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { Link } from "gatsby";

const Header = ({ location, title }) => {
    const [showCta, setShowCta] = useState(false);

    useScrollPosition(
        (scroll) => {
            const passThreshold = scroll.currPos.y < -550;
            setShowCta(passThreshold);
        },
        [showCta]
    );

    return (
        <>
            <header>
                <Container>
                    <div className="d-flex justify-content-center justify-content-md-between">
                        <Link to={"/"} className={styles.brandLink}>
                            <FlexSpace>
                                <Logo className={styles.brandLogo} />
                                <h1 className={styles.brandName}>
                                    Unifyd
                                </h1>
                            </FlexSpace>
                        </Link>
                        <Link
                            to={"/contact"}
                            className={
                                showCta
                                    ? `d-none d-md-block ${styles.ctaButtonFull}`
                                    : `d-none d-md-block ${styles.ctaButtonOutline}`
                            }
                        >
                            Get Eearly Access
                        </Link>
                    </div>
                </Container>
            </header>
        </>
    );
};

export default Header;

import React from "react";
import * as styles from "./inner-page-header.module.scss";
import Container from "../global/container";

const InnerPageHeader = ({ pageTitle }) => {
    return (
        <section className={styles.innerPageHeader}>
            <div className={styles.headerPattern}>
                <img src="../../pattern-dark.svg" alt="Background Pattern" />
            </div>
            <Container className="h-100 pt-60">
                <div className="row h-100 align-items-center justify-content-center position-relative z-index-3">
                    <div className="col-12 text-center">
                        <h1 className="text-white font-weight-bold line-height-3 font-size-xl mb-10">
                            {pageTitle}
                        </h1>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default InnerPageHeader;
